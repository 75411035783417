import * as Yup from "yup"
import { dateRegex, shareList, phoneRegex, standardRequiredText } from "../../constants"
import { parseDateString, switchDateFormat } from "../../utils/form-data"
import moment from "moment"

const referenceFields = {
  name: Yup.string().required(standardRequiredText),
  company: Yup.string().required(standardRequiredText),
  job_title: Yup.string().required(standardRequiredText),
  contact: Yup.string()
    .matches(phoneRegex, "Must be a valid phone number")
    .required(standardRequiredText),
  email: Yup.string()
    .email("Must be a valid email address in the format 'example@example.com'")
    .required(standardRequiredText),
  address: Yup.string().required(standardRequiredText),
}

const companyFields = {
  company_name: Yup.string().required(standardRequiredText),
  job_title: Yup.string().required(standardRequiredText),
  date_from: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  date_to: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  reason_for_leaving: Yup.string(),
  duties: Yup.string(),
}

export const validationSchema = Yup.object().shape({
  consultant_name: Yup.string().required(standardRequiredText),
  first_name: Yup.string().required(standardRequiredText),
  surname: Yup.string().required(standardRequiredText),
  title: Yup.string().required(standardRequiredText),
  date_of_birth: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  address_1: Yup.string().required(standardRequiredText),
  address_2: Yup.string(),
  address_3: Yup.string(),
  address_town: Yup.string().required(standardRequiredText),
  postcode: Yup.string().required(standardRequiredText),
  country: Yup.string().required(standardRequiredText),
  postcode: Yup.string().required(standardRequiredText),
  email: Yup.string()
    .email("Must be a valid email address in the format 'example@example.com'")
    .required(standardRequiredText),
  mobile: Yup.string()
    .matches(phoneRegex, "Must be a valid phone number")
    .required(standardRequiredText),
  landline: Yup.string().matches(phoneRegex, "Must be a valid phone number"),
  when_can_you_start: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  can_you_work: Yup.string().required(standardRequiredText),
  national_insurance: Yup.string().required(standardRequiredText),
  has_full_driving_licence: Yup.string().required(standardRequiredText),
  method_of_transport: Yup.array()
    .min(1)
    .required(standardRequiredText),
  own_transport: Yup.string().required(standardRequiredText),
  right_to_work_type: Yup.string(),
  right_to_work_number: Yup.string(),
  emergency_name: Yup.string().required(standardRequiredText),
  emergency_tel: Yup.string().required(standardRequiredText),
  emergency_relation: Yup.string().required(standardRequiredText),
  nationality: Yup.string().required(standardRequiredText),
  date_arrived: Yup.string().when('nationality', {
    is: (nationality) => nationality !== "United Kingdom",
    then: Yup.string().matches(
      dateRegex,
      "Must be a valid date in the format dd/mm/yyyy"
    ).required(standardRequiredText),
  }),
  share_code: Yup.string().when(['nationality', 'date_arrived'], {
    is:
      (nationality, date_arrived) => shareList.includes(nationality) && date_arrived && switchDateFormat(date_arrived) <= new Date("12/31/2020"),
    then: Yup.string().required(standardRequiredText).max(9, "Max 9 Characters"),
  }),
  passport_number: Yup.number().typeError("Must be a number"),
  passport_issue_date: Yup.string().matches(
    dateRegex,
    "Must be a valid date in the format dd/mm/yyyy"
  ),
  passport_expiry_date: Yup.string().matches(
    dateRegex,
    "Must be a valid date in the format dd/mm/yyyy"
  ),
  medical_conditions: Yup.string().required(standardRequiredText),
  medical_conditions_details: Yup.string().when("medical_conditions", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  bank_name: Yup.string().required(standardRequiredText),
  sort_code: Yup.string().required(standardRequiredText),
  account_number: Yup.number("Account number must be a number")
    .required(standardRequiredText)
    .max(99999999, "UK account numbers are 8 characters long"),
  entitled_work_uk: Yup.string().required(standardRequiredText),
  entitled_work_uk_details: Yup.string().when("entitled_work_uk", {
    is: "No",
    then: Yup.string().required(standardRequiredText),
  }),
  medical_conditions_requirements: Yup.string().required(standardRequiredText),
  education: Yup.object().shape({
    is_required: Yup.string().required(standardRequiredText),
    facility_name: Yup.string(),
    course_name: Yup.string(),
    start_date: Yup.string(),
    end_date: Yup.string(),
    qualifications: Yup.string(),
    address: Yup.string(),
    contact_name: Yup.string(),
    contact_number: Yup.string(),
  }),
  employment_history: Yup.array()
    .of(Yup.object().shape(companyFields))
    .min(1),
  employer_contact: 0,
  references: Yup.array()
    .of(Yup.object().shape(referenceFields))
    .min(1),
  health_and_medical_questionnaire: Yup.object().shape({
    eyesight_and_vision: Yup.object().shape({
      any_problems: Yup.string().required(standardRequiredText),
      wears_glasses: Yup.string().required(standardRequiredText),
      problems_corrected_by_glasses: Yup.string().required(
        standardRequiredText
      ),
      colour_blind: Yup.string().required(standardRequiredText),
      difficulty_differentiating_colours: Yup.string().required(
        standardRequiredText
      ),
      has_had_eye_operation: Yup.string().required(standardRequiredText),
      has_had_retina_scan: Yup.string().required(standardRequiredText),
      condition_details: Yup.string(),
    }),
    heart_and_lungs: Yup.object().shape({
      has_suffered_from_high_blood_pressure: Yup.string().required(
        standardRequiredText
      ),
      has_had_stroke_or_heart_attack: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_heart_disease: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_angina: Yup.string().required(standardRequiredText),
      has_had_heart_surgery: Yup.string().required(standardRequiredText),
      has_suffered_from_asthma_bronchitis_or_similar: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_disease_not_mentioned: Yup.string().required(
        standardRequiredText
      ),
      condition_details: Yup.string(),
    }),
    back: Yup.object().shape({
      has_suffered_from_sciatica: Yup.string().required(standardRequiredText),
      has_suffered_from_lumbago: Yup.string().required(standardRequiredText),
      has_suffered_from_slipped_disc: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_prolapsed_disc: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_other_back_problems: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_arthritis: Yup.string().required(standardRequiredText),
      has_suffered_from_other_form_of_inflammation: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_repetitive_strain_injury: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_white_finger_or_carpel_tunne_syndrome: Yup.string().required(
        standardRequiredText
      ),
      has_restricted_mobility_of_arms_hands_or_fingers: Yup.string().required(
        standardRequiredText
      ),
      has_restrictions_to_general_mobility: Yup.string().required(
        standardRequiredText
      ),
      has_been_disqualified_from_driving_due_to_ill_health: Yup.string().required(
        standardRequiredText
      ),
      is_registered_as_disabled: Yup.string().required(standardRequiredText),
      condition_details: Yup.string(),
    }),
    general: Yup.object().shape({
      has_had_serious_head_injury: Yup.string().required(standardRequiredText),
      has_suffered_from_nervous_disorders: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_depression: Yup.string().required(standardRequiredText),
      has_suffered_from_drug_alcohol_addiction: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_diabetes: Yup.string().required(standardRequiredText),
      has_suffered_from_epilepsy: Yup.string().required(standardRequiredText),
      suffers_from_full_or_partial_deafness: Yup.string().required(
        standardRequiredText
      ),
      has_become_sensitized_to_chemical_substances: Yup.string().required(
        standardRequiredText
      ),
      has_had_accident_at_work: Yup.string().required(standardRequiredText),
      has_injured_fingers_hands_wrist_elbow_or_arm: Yup.string().required(
        standardRequiredText
      ),
      has_had_arm_operation_or_injections_to_wrist: Yup.string().required(
        standardRequiredText
      ),
      has_other_medical_related_matters: Yup.string().required(
        standardRequiredText
      ),
      condition_details: Yup.string(),
    }),
    consent_to_data_sharing: Yup.number().oneOf([1], standardRequiredText),
  }),
  clonazepam: Yup.string().required(standardRequiredText),
  diazepam: Yup.string().required(standardRequiredText),
  flunitrazepam: Yup.string().required(standardRequiredText),
  lorazepam: Yup.string().required(standardRequiredText),
  morphine_opiate: Yup.string().required(standardRequiredText),
  methadone: Yup.string().required(standardRequiredText),
  oxzaepam: Yup.string().required(standardRequiredText),
  temazepam: Yup.string().required(standardRequiredText),
  criminal_convictions: Yup.string().required(standardRequiredText),

  //-----checkbox list
  large_and_small_vans: Yup.number(),
  light_vans: Yup.number(),
  catalogue: Yup.number(),
  multidrop_10: Yup.number(),
  multidrop_20: Yup.number(),
  multidrop_30: Yup.number(),
  multidrop_40: Yup.number(),
  multidrop_50_plus: Yup.number(),
  parcel_delivery_7_tonne: Yup.number(),
  // ----- end of checkbox list

  motor_offence: Yup.string().required(standardRequiredText),
  license_suspended: Yup.string().required(standardRequiredText),
  prosecution_pending: Yup.string().required(standardRequiredText),
  physical_mental_defect: Yup.string().required(standardRequiredText),

  criminal_record_disclosure: Yup.string().required(standardRequiredText),
  criminal_record_disclosure_details: Yup.string().when(
    "criminal_record_disclosure",
    {
      is: "Yes",
      then: Yup.string().required(standardRequiredText),
    }
  ),
  criminal_offence_under_rehab_act: Yup.string().required(standardRequiredText),
  criminal_offence_under_rehab_act_details: Yup.string().when(
    "criminal_offence_under_rehab_act",
    {
      is: "Yes",
      then: Yup.string().required(standardRequiredText),
    }
  ),
  previous_convictions: Yup.string().required(standardRequiredText),
  previous_convictions_details: Yup.string().when("previous_convictions", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  security_clearance: Yup.string().required(standardRequiredText),
  security_clearance_date_start: Yup.date().when("security_clearance", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  security_clearance_date_end: Yup.date().when("security_clearance", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  security_clearance_place_of_work: Yup.string().when("security_clearance", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  temporary_worker_name: Yup.string().required(standardRequiredText),
  employment_business: Yup.string().required(standardRequiredText),

  highwayTest: Yup.object().shape({
    question_one: Yup.string().required(standardRequiredText),
    question_two: Yup.string().required(standardRequiredText),
    question_three: Yup.string().required(standardRequiredText),
    question_four: Yup.string().required(standardRequiredText),
    question_five: Yup.string().required(standardRequiredText),
    question_six: Yup.string().required(standardRequiredText),
    question_seven: Yup.string().required(standardRequiredText),
    question_eight: Yup.string().required(standardRequiredText),
    question_nine: Yup.string().required(standardRequiredText),
    question_ten: Yup.string().required(standardRequiredText),
    question_eleven: Yup.string().required(standardRequiredText),
    question_twelve: Yup.string().required(standardRequiredText),
  }),

  right_to_work_documents: Yup.array()
    .max(2, "Maximum of 2 documents")
    .required(standardRequiredText),
  cv: Yup.mixed()
    .nullable()
    .required(standardRequiredText),
  documents: Yup.array().max(2, "Maximum of 2 documents"),
})
