export const initialValues = {
  consultant_name: "",
  first_name: "",
  surname: "",
  title: "",
  date_of_birth: "",
  address_1: "",
  address_2: "",
  address_3: "",
  address_town: "",
  postcode: "",
  country: "",
  email: "",
  mobile: "",
  landline: "",
  when_can_you_start: "",
  can_you_work: "",
  national_insurance: "",
  has_full_driving_licence: "",
  method_of_transport: [],
  own_transport: "",
  right_to_work_type: "",
  right_to_work_number: "",
  emergency_name: "",
  emergency_tel: "",
  emergency_relation: "",
  nationality: "",
  date_arrived: "",
  share_code: "",
  passport_number: "",
  passport_issue_date: "",
  passport_expiry_date: "",
  medical_conditions: "",
  medical_conditions_details: "",
  bank_name: "",
  sort_code: "",
  account_number: "",
  entitled_work_uk: "",
  entitled_work_uk_details: "",
  medical_conditions_requirements: "",
  education: {
    is_required: "",
    facility_name: "",
    course_name: "",
    start_date: "",
    end_date: "",
    qualifications: "",
    address: "",
    contact_name: "",
    contact_number: "",
    flt_plant_license: "n/a",
  },
  employment_history: [
    {
      company_name: "",
      job_title: "",
      date_from: "",
      date_to: "",
      reason_for_leaving: "",
      duties: "",
    },
  ],
  employer_contact: 0,
  references: [
    {
      name: "",
      company: "",
      job_title: "",
      contact: "",
      email: "",
      address: "",
    },
  ],
  health_and_medical_questionnaire: {
    eyesight_and_vision: {
      any_problems: "",
      wears_glasses: "",
      problems_corrected_by_glasses: "",
      colour_blind: "",
      difficulty_differentiating_colours: "",
      has_had_eye_operation: "",
      has_had_retina_scan: "",
      condition_details: "",
    },
    heart_and_lungs: {
      has_suffered_from_high_blood_pressure: "",
      has_had_stroke_or_heart_attack: "",
      has_suffered_from_heart_disease: "",
      has_suffered_from_angina: "",
      has_had_heart_surgery: "",
      has_suffered_from_asthma_bronchitis_or_similar: "",
      has_suffered_from_disease_not_mentioned: "",
      condition_details: "",
    },
    back: {
      has_suffered_from_sciatica: "",
      has_suffered_from_lumbago: "",
      has_suffered_from_slipped_disc: "",
      has_suffered_from_prolapsed_disc: "",
      has_suffered_from_other_back_problems: "",
      has_suffered_from_arthritis: "",
      has_suffered_from_other_form_of_inflammation: "",
      has_suffered_from_repetitive_strain_injury: "",
      has_suffered_from_white_finger_or_carpel_tunne_syndrome: "",
      has_restricted_mobility_of_arms_hands_or_fingers: "",
      has_restrictions_to_general_mobility: "",
      has_been_disqualified_from_driving_due_to_ill_health: "",
      is_registered_as_disabled: "",
      condition_details: "",
    },
    general: {
      has_had_serious_head_injury: "",
      has_suffered_from_nervous_disorders: "",
      has_suffered_from_depression: "",
      has_suffered_from_drug_alcohol_addiction: "",
      has_suffered_from_diabetes: "",
      has_suffered_from_epilepsy: "",
      suffers_from_full_or_partial_deafness: "",
      has_become_sensitized_to_chemical_substances: "",
      has_had_accident_at_work: "",
      has_injured_fingers_hands_wrist_elbow_or_arm: "",
      has_had_arm_operation_or_injections_to_wrist: "",
      has_other_medical_related_matters: "",
      condition_details: "",
    },
    consent_to_data_sharing: 0,
  },
  clonazepam: "",
  diazepam: "",
  flunitrazepam: "",
  lorazepam: "",
  morphine_opiate: "",
  methadone: "",
  oxzaepam: "",
  temazepam: "",
  criminal_convictions: "",
  //-----checkbox list
  large_and_small_vans: 0,
  light_vans: 0,
  catalogue: 0,
  multidrop_10: 0,
  multidrop_20: 0,
  multidrop_30: 0,
  multidrop_40: 0,
  multidrop_50_plus: 0,
  parcel_delivery_7_tonne: 0,
  // ----- end of checkbox list
  motor_offence: "",
  license_suspended: "",
  prosecution_pending: "",
  physical_mental_defect: "",

  criminal_record_disclosure: "",
  criminal_record_disclosure_details: "",
  criminal_offence_under_rehab_act: "",
  criminal_offence_under_rehab_act_details: "",
  previous_convictions: "",
  previous_convictions_details: "",
  security_clearance: "",
  security_clearance_date_start: "",
  security_clearance_date_end: "",
  security_clearance_place_of_work: "",

  temporary_worker_name: "",
  employment_business: "",
  highwayTest: {
    question_one: "",
    question_two: "",
    question_three: "",
    question_four: "",
    question_five: "",
    question_six: "",
    question_seven: "",
    question_eight: "",
    question_nine: "",
    question_ten: "",
    question_eleven: "",
    question_twelve: "",
  },
  right_to_work_documents: [],
  cv: null,
  documents: [],
}
