import React from "react"
import { FormikCheckbox, FormikTabs, FormikText } from "../form"
import { FieldSet } from "../../style/containers"

const HealthAndMedicalQuestionnaire = ({ setFieldValue, values }) => {
  return (
    <>
      <p>
        These questions are vital to your safety and others working with you.
        Please ensure we know of any medical condition if different from below
        prior to employment. Failure to reveal relevant information may result
        in disciplinary action and / or dismissal. Your answers shall be treated
        in the strictest of confidence.
      </p>
      <FieldSet>
        <h3>Eyesight and vision</h3>
        <FormikTabs
          name="health_and_medical_questionnaire.eyesight_and_vision.any_problems"
          label="Do you have any problems with your eyesight?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.eyesight_and_vision.wears_glasses"
          label="Do you need to wear spectacles or contact lenses for any activity?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.eyesight_and_vision.problems_corrected_by_glasses"
          label="Do you suffer from any problems with your eyesight that are not corrected by wearing the above?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.eyesight_and_vision.colour_blind"
          label="Do you suffer from colour blindness?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.eyesight_and_vision.difficulty_differentiating_colours"
          label="Do you experience any difficulty in differentiating colours?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.eyesight_and_vision.has_had_eye_operation"
          label="Have you ever had an eye operation?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.eyesight_and_vision.has_had_retina_scan"
          label="Have you ever had a retina scan?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        {values.health_and_medical_questionnaire.eyesight_and_vision
          .any_problems === "Yes" ||
        values.health_and_medical_questionnaire.eyesight_and_vision
          .wears_glasses === "Yes" ||
        values.health_and_medical_questionnaire.eyesight_and_vision
          .problems_corrected_by_glasses === "Yes" ||
        values.health_and_medical_questionnaire.eyesight_and_vision
          .difficulty_differentiating_colours === "Yes" ||
        values.health_and_medical_questionnaire.eyesight_and_vision
          .difficulty_differentiating_colours === "Yes" ||
        values.health_and_medical_questionnaire.eyesight_and_vision
          .has_had_eye_operation === "Yes" ||
        values.health_and_medical_questionnaire.eyesight_and_vision
          .has_had_retina_scan === "Yes" ? (
          <FormikText
            name="health_and_medical_questionnaire.eyesight_and_vision.condition_details"
            label="You answered yes to one (or more) of the questions above, please give brief details"
          />
        ) : (
          ""
        )}
      </FieldSet>
      <FieldSet>
        <h3>Heart and Lungs</h3>
        <FormikTabs
          name="health_and_medical_questionnaire.heart_and_lungs.has_suffered_from_high_blood_pressure"
          label="Have you ever suffered from high blood pressure?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.heart_and_lungs.has_had_stroke_or_heart_attack"
          label="Have you ever suffered from a stroke or heart attack?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.heart_and_lungs.has_suffered_from_heart_disease"
          label="Have you ever suffered from heart disease?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.heart_and_lungs.has_suffered_from_angina"
          label="Have you ever suffered from angina?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.heart_and_lungs.has_had_heart_surgery"
          label="Have you ever undergone heart surgery?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.heart_and_lungs.has_suffered_from_asthma_bronchitis_or_similar"
          label="Have you ever suffered from asthma, bronchitis or similar?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.heart_and_lungs.has_suffered_from_disease_not_mentioned"
          label="Have you ever suffered from a disease not mentioned above?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        {values.health_and_medical_questionnaire.heart_and_lungs
          .has_suffered_from_high_blood_pressure === "Yes" ||
        values.health_and_medical_questionnaire.heart_and_lungs
          .has_had_stroke_or_heart_attack === "Yes" ||
        values.health_and_medical_questionnaire.heart_and_lungs
          .has_suffered_from_heart_disease === "Yes" ||
        values.health_and_medical_questionnaire.heart_and_lungs
          .has_suffered_from_angina === "Yes" ||
        values.health_and_medical_questionnaire.heart_and_lungs
          .has_had_heart_surgery === "Yes" ||
        values.health_and_medical_questionnaire.heart_and_lungs
          .has_suffered_from_asthma_bronchitis_or_similar === "Yes" ||
        values.health_and_medical_questionnaire.heart_and_lungs
          .has_suffered_from_disease_not_mentioned === "Yes" ? (
          <FormikText
            name="health_and_medical_questionnaire.heart_and_lungs.condition_details"
            label="You answered yes to one (or more) of the questions above, please give brief details"
          />
        ) : (
          ""
        )}
      </FieldSet>
      <FieldSet>
        <h3>Back</h3>
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_suffered_from_sciatica"
          label="Have you ever suffered from sciatica?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_suffered_from_lumbago"
          label="Have you ever suffered from lumbago?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_suffered_from_slipped_disc"
          label="Have you ever suffered from a slipped disc?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_suffered_from_prolapsed_disc"
          label="Have you ever suffered from a prolapsed disc?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_suffered_from_other_back_problems"
          label="Have you ever experienced any other form of back problem?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <h3>Dexterity</h3>
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_suffered_from_arthritis"
          label="Have you ever suffered from arthritis?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_suffered_from_other_form_of_inflammation"
          label="Have you ever suffered from any other form or inflammation?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_suffered_from_repetitive_strain_injury"
          label="Have you ever suffered from repetitive strain injury?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_suffered_from_white_finger_or_carpel_tunne_syndrome"
          label="Have you ever suffered from white finger or Carpel Tunnel Syndrome?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_restricted_mobility_of_arms_hands_or_fingers"
          label="Have you any restrictions to the mobility of your arms, hands or fingers?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_restrictions_to_general_mobility"
          label="Do you suffer from any restrictions to your general mobility?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.has_been_disqualified_from_driving_due_to_ill_health"
          label="Have you ever been disqualified from driving due to ill-health?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.back.is_registered_as_disabled"
          label="Are you registered disabled?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        {values.health_and_medical_questionnaire.back
          .has_suffered_from_sciatica === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_suffered_from_lumbago === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_suffered_from_slipped_disc === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_suffered_from_prolapsed_disc === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_suffered_from_other_back_problems === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_suffered_from_arthritis === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_suffered_from_other_form_of_inflammation === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_suffered_from_repetitive_strain_injury === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_suffered_from_white_finger_or_carpel_tunne_syndrome === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_restricted_mobility_of_arms_hands_or_fingers === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_restrictions_to_general_mobility === "Yes" ||
        values.health_and_medical_questionnaire.back
          .has_been_disqualified_from_driving_due_to_ill_health === "Yes" ||
        values.health_and_medical_questionnaire.back
          .is_registered_as_disabled === "Yes" ? (
          <FormikText
            name="health_and_medical_questionnaire.back.condition_details"
            label="You answered yes to one (or more) of the questions above, please give brief details"
          />
        ) : (
          ""
        )}
      </FieldSet>
      <FieldSet>
        <h3>General</h3>
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_had_serious_head_injury"
          label="Have you ever suffered a serious head injury?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_suffered_from_nervous_disorders"
          label="Have you ever suffered from any nervous disorders?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_suffered_from_depression"
          label="Have you ever suffered from depression?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_suffered_from_drug_alcohol_addiction"
          label="Have you ever suffered from alcohol or drug addiction?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_suffered_from_diabetes"
          label="Do you suffer from diabetes?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_suffered_from_epilepsy"
          label="Have you ever suffered from epilepsy?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.suffers_from_full_or_partial_deafness"
          label="Do you suffer full or partial deafness in either ear?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_become_sensitized_to_chemical_substances"
          label="Have you ever become sensitized to any chemical substances at work?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_had_accident_at_work"
          label="Have you ever had an accident at work that has resulted in injury to yourself?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_injured_fingers_hands_wrist_elbow_or_arm"
          label="Have you ever had an injury to your fingers, hands, wrist, elbow or arm?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_had_arm_operation_or_injections_to_wrist"
          label="Have you ever had an operation on any part of your hand or arm or undergone injections to your wrist?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <FormikTabs
          name="health_and_medical_questionnaire.general.has_other_medical_related_matters"
          label="Are there any other medical related matters that we need to be made aware of?"
          setFieldValue={setFieldValue}
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        {values.health_and_medical_questionnaire.general
          .has_had_serious_head_injury === "Yes" ||
        values.health_and_medical_questionnaire.general
          .has_suffered_from_nervous_disorders === "Yes" ||
        values.health_and_medical_questionnaire.general
          .has_suffered_from_depression === "Yes" ||
        values.health_and_medical_questionnaire.general
          .has_suffered_from_diabetes === "Yes" ||
        values.health_and_medical_questionnaire.general
          .has_suffered_from_epilepsy === "Yes" ||
        values.health_and_medical_questionnaire.general
          .suffers_from_full_or_partial_deafness === "Yes" ||
        values.health_and_medical_questionnaire.general
          .has_become_sensitized_to_chemical_substances === "Yes" ||
        values.health_and_medical_questionnaire.general
          .has_had_accident_at_work === "Yes" ||
        values.health_and_medical_questionnaire.general
          .has_injured_fingers_hands_wrist_elbow_or_arm === "Yes" ||
        values.health_and_medical_questionnaire.general
          .has_had_arm_operation_or_injections_to_wrist === "Yes" ||
        values.health_and_medical_questionnaire.general
          .has_other_medical_related_matters === "Yes" ? (
          <FormikText
            name="health_and_medical_questionnaire.general.condition_details"
            label="You answered yes to one (or more) of the questions above, please give brief details"
          />
        ) : (
          ""
        )}
      </FieldSet>
      <FormikCheckbox
        bold={true}
        label="I understand and agree that the information given on this medical
          questionnaire can be viewed by any third party client of Pin Point
          Recruitment"
        name="health_and_medical_questionnaire.consent_to_data_sharing"
      />
    </>
  )
}

export default HealthAndMedicalQuestionnaire
